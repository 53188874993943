/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import '../styles/Profile.css';
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

export const Profile = () => {

    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);

    const HandleSignOut = async (e) => {
        signOut(auth)
        navigate("/")
    }

    if (!currentUser) {
        navigate("/")
    }

    return (
        <>
            <div class="wrapper-profile">
                <div class="profile-card js-profile-card">
                    <div class="profile-card__img">
                        <img src={currentUser.photoURL} alt="profile card" />
                    </div>
                    <div class="profile-card__cnt js-profile-cnt">
                        <div class="profile-card__name">{currentUser.displayName}</div>
                        <div class="profile-card__txt"><strong>E-Mail : </strong>{currentUser.email}</div>
                        <div class="profile-card-inf">
                            {/* <div class="profile-card-inf__item">
                                <div class="profile-card-inf__title">1598</div>
                                <div class="profile-card-inf__txt">Followers</div>
                            </div>

                            <div class="profile-card-inf__item">
                                <div class="profile-card-inf__title">65</div>
                                <div class="profile-card-inf__txt">Following</div>
                            </div>

                            <div class="profile-card-inf__item">
                                <div class="profile-card-inf__title">123</div>
                                <div class="profile-card-inf__txt">Articles</div>
                            </div>

                            <div class="profile-card-inf__item">
                                <div class="profile-card-inf__title">85</div>
                                <div class="profile-card-inf__txt">Works</div>
                            </div>*/}
                        </div>

                        <div class="profile-card-ctr">
                            <button class="profile-card__button button--blue js-message-btn" onClick={() => navigate("/Profile/Edit")}>Edit Profile</button>
                            <button class="profile-card__button button--orange" onClick={HandleSignOut}>Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
