/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { Navbar } from './Navbar';

export const Home = () => {

    $(document).ready(function () {
        $("a").on("click", function (event) {
            if (this.hash !== "") {
                event.preventDefault();

                var hash = this.hash;
                $("html, body").animate(
                    {
                        scrollTop: $(hash).offset().top,
                    },
                    800,
                    function () {
                        window.location.hash = hash;
                    }
                );
            }
        });
    });

    $(".menu-items a").click(function () {
        $("#checkbox").prop("checked", false);
    });

    return (
        <>
            {/* <section className="top-txt">
                <div className="head container ">
                    <div className="head-txt ">
                        <p>Free shipping, 30-day return or refund guarantee.</p>
                    </div>
                    <div className="sing_in_up ">
                        <Link to="/login">SIGN IN</Link>
                    </div>
                </div>
            </section> */}
            <Navbar />
            <section id="home">
                <div className="home_page">
                    {/* <div className="home_img ">
                        <img src="https://st.depositphotos.com/1916583/2740/i/600/depositphotos_27407329-stock-photo-eyeglasses-shades-and-sunglasses-in.jpg" alt="img " />
                    </div> */}
                    <div className="home_txt ">
                        <p className="collection">SUMMER COLLECTION</p>
                        <h2>
                            NEW COLLECTION 2023
                        </h2>
                        <div className="home_label ">
                            <p>
                                Lens is a company that specializes in creating high-quality lenses for cameras, telescopes, microscopes, and other optical devices.
                            </p>
                        </div>
                        <button>
                            <Link to="/Collection">SHOP NOW</Link>
                            <i className="bx bx-right-arrow-alt" />
                        </button>
                        <div className="home_social_icons">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com">
                                <i className="bx bxl-facebook" />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com">
                                <i className="bx bxl-twitter" />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.pinterest.com">
                                <i className="bx bxl-pinterest" />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com">
                                <i className="bx bxl-instagram" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="collection">
                <div className="collections container">
                    <div className="content">
                        <img src="https://i.postimg.cc/MHv7KJYp/access.webp" alt="img" />
                        <div className="img-content">
                            <p>Eyeglasses</p>
                            <button className='mt-4'>
                                <Link to="/Buy/Eyeglasses">SHOP NOW</Link>
                            </button>
                        </div>
                    </div>
                    <div className="content2">
                        <img src="https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//v/i/Gold-Brown-Green-Full-Rim-Aviator-Vincent-Chase-LIVEWIRE-VC-S14493-C2-Sunglasses_vincent-chase-vc-s14493-c2-sunglasses_G_877218_02_2022.jpg" alt="img" />
                        <div className="img-content2">
                            <p>Sunglasses</p>
                            <button className='mt-4'>
                                <Link to="/Buy/Sunglasses">SHOP NOW</Link>
                            </button>
                        </div>
                    </div>
                    <div className="content3">
                        <img src="https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/i/blue-block-phone-&-computer-glasses:-black-full-rim-lenskart-blu-lb-e14283-c4_lenskart-blu-lb-e14283-c4-eyeglasses_lenskart-blu-lb-e14283-c4-eyeglasses_g_700325_02_2022.jpg" alt="img" />
                        <div className="img-content3">
                            <p>Progressive Lenses</p>
                            <button className='mt-4'>
                                <Link to="/Buy/Progessive">SHOP NOW</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section id="news">
                <div className="news-heading">
                    <p>LATEST NEWS</p>
                    <h2>Fashion New Trends</h2>
                </div>
                <div className="l-news container">
                    <div className="l-news1">
                        <div className="news1-img">
                            <img src="https://i.postimg.cc/2y6wbZCm/news1.jpg" alt="img" />
                        </div>
                        <div className="news1-conte">
                            <div className="date-news1">
                                <p>
                                    <i className="bx bxs-calendar" /> 12 February 2022
                                </p>
                                <h4>What Curling Irons Are The Best Ones</h4>
                                <Link
                                    to="https://www.vogue.com/article/best-curling-irons"
                                    target="_blank"
                                >
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="l-news2">
                        <div className="news2-img">
                            <img src="https://i.postimg.cc/9MXPK7RT/news2.jpg" alt="img" />
                        </div>
                        <div className="news2-conte">
                            <div className="date-news2">
                                <p>
                                    <i className="bx bxs-calendar" /> 17 February 2022
                                </p>
                                <h4>The Health Benefits Of Sunglasses</h4>
                                <Link
                                    to="https://www.rivieraopticare.com/blog/314864-the-health-benefits-of-wearing-sunglasses_2/"
                                    target="_blank"
                                >
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="l-news3">
                        <div className="news3-img">
                            <img src="https://i.postimg.cc/x1KKdRLM/news3.jpg" alt="img" />
                        </div>
                        <div className="news3-conte">
                            <div className="date-news3">
                                <p>
                                    <i className="bx bxs-calendar" /> 26 February 202
                                </p>
                                <h4>Eternity Bands Do Last Forever</h4>
                                <Link
                                    to="https://www.briangavindiamonds.com/news/eternity-bands-symbolize-love-that-lasts-forever/"
                                    target="_blank"
                                >
                                    read more
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <footer>
                <div className="footer-container container">
                    <div className="content_1">
                        {/* <img src="https://i.postimg.cc/htGyQ4JB/footer-logo.png" alt="logo" /> */}
                        <h1 className="text-2xl font-extrabold text-white">LOGO</h1>
                        <p>
                            Lensify is the ultimate choice for anyone <br /> who wants to capture the world in stunning <br /> detail and clarity.
                        </p>
                        {/* <img src="https://i.postimg.cc/Nj9dgJ98/cards.png" alt="cards" /> */}
                    </div>
                    <div className="content_2">
                        <h4>SERVICES</h4>
                        <Link to="/">Store Locator</Link>
                        <Link to="/">Try Lenses</Link>
                        <Link to="/">Buying Guide</Link>
                        <Link to="/">Eye Checkup</Link>
                    </div>
                    <div className="content_3">
                        <h4>SHOPPING</h4>
                        {/* <Link to="https://payment-method-sb.netlify.app/" target="_blank"> */}
                        <Link to="/" target="_blank">
                            Payment Method
                        </Link>
                        {/* <Link to="https://delivery-status-sb.netlify.app/" target="_blank"> */}
                        <Link to="/" target="_blank">
                            Delivery
                        </Link>
                        {/* <Link to="https://codepen.io/sandeshbodake/full/Jexxrv" target="_blank"> */}
                        <Link to="/" target="_blank">
                            Return and Exchange
                        </Link>
                    </div>
                    <div className="content_4">
                        <h4>NEWSLETTER</h4>
                        <p>
                            Be the first to know about new
                            arrivals, look books, sales & promos!
                        </p>
                        <div className="f-mail">
                            <input type="email" placeholder="Your Email" />
                            {/* <i className="bx bx-envelope" /> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
