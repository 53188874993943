import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Navbar } from './Navbar'

export const Articles = () => {

    const { Article } = useParams();

    return (
        <>
            <Navbar />
            <section id="sellers">
                <div className="seller container">
                    {Article === "Eyeglasses" ?
                        <>
                            <h2 className="uppercase">{Article}</h2>
                            <div className="best-seller">
                                <div className="best-p1">
                                    <img src="https://i.postimg.cc/MHv7KJYp/access.webp" alt="img" />
                                    <div className="best-p1-txt">
                                        <div className="name-of-p">
                                            <p>Eyeglasses</p>
                                        </div>
                                        <div className="rating">
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                        </div>
                                        <div className="price">
                                            $20.24
                                            <div className="colors">
                                                <i className="bx bxs-circle red" />
                                                <i className="bx bxs-circle blue" />
                                                <i className="bx bxs-circle white" />
                                            </div>
                                        </div>
                                        <div className="buy-now">
                                            <button>
                                                <Link to="/Article/Sunglasses">
                                                    Buy Now
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="best-p1">
                                    <img src="https://i.postimg.cc/MHv7KJYp/access.webp" alt="img" />
                                    <div className="best-p1-txt">
                                        <div className="name-of-p">
                                            <p>Eyeglasses</p>
                                        </div>
                                        <div className="rating">
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                        </div>
                                        <div className="price">
                                            $20.24
                                            <div className="colors">
                                                <i className="bx bxs-circle red" />
                                                <i className="bx bxs-circle blue" />
                                                <i className="bx bxs-circle white" />
                                            </div>
                                        </div>
                                        <div className="buy-now">
                                            <button>
                                                <Link to="/Article/Sunglasses">
                                                    Buy Now
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="best-p1">
                                    <img src="https://i.postimg.cc/MHv7KJYp/access.webp" alt="img" />
                                    <div className="best-p1-txt">
                                        <div className="name-of-p">
                                            <p>Eyeglasses</p>
                                        </div>
                                        <div className="rating">
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                        </div>
                                        <div className="price">
                                            $20.24
                                            <div className="colors">
                                                <i className="bx bxs-circle red" />
                                                <i className="bx bxs-circle blue" />
                                                <i className="bx bxs-circle white" />
                                            </div>
                                        </div>
                                        <div className="buy-now">
                                            <button>
                                                <Link to="/Article/Sunglasses">
                                                    Buy Now
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="best-p1">
                                    <img src="https://i.postimg.cc/MHv7KJYp/access.webp" alt="img" />
                                    <div className="best-p1-txt">
                                        <div className="name-of-p">
                                            <p>Eyeglasses</p>
                                        </div>
                                        <div className="rating">
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                        </div>
                                        <div className="price">
                                            $20.24
                                            <div className="colors">
                                                <i className="bx bxs-circle red" />
                                                <i className="bx bxs-circle blue" />
                                                <i className="bx bxs-circle white" />
                                            </div>
                                        </div>
                                        <div className="buy-now">
                                            <button>
                                                <Link to="/Article/Sunglasses">
                                                    Buy Now
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : Article === "Sunglasses" ?
                            <>
                                <h2 className="uppercase">{Article}</h2>
                                <div className="best-seller">
                                    <div className="best-p1">
                                        <img src="https://cdn.shopify.com/s/files/1/0238/8312/0717/products/01_ceaeea92-8fed-4d1e-83ac-4b513ef5413d_1512x.jpg?v=1671798553" alt="img" />
                                        <div className="best-p1-txt">
                                            <div className="name-of-p">
                                                <p>Sunglasses</p>
                                            </div>
                                            <div className="rating">
                                                <i className="bx bxs-star" />
                                                <i className="bx bxs-star" />
                                                <i className="bx bxs-star" />
                                                <i className="bx bx-star" />
                                                <i className="bx bx-star" />
                                            </div>
                                            <div className="price">
                                                $20.24
                                                <div className="colors">
                                                    <i className="bx bxs-circle red" />
                                                    <i className="bx bxs-circle blue" />
                                                    <i className="bx bxs-circle white" />
                                                </div>
                                            </div>
                                            <div className="buy-now">
                                                <button>
                                                    <Link to="/Article/Sunglasses">
                                                        Buy Now
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="best-p1">
                                        <img src="https://cdn.shopify.com/s/files/1/0238/8312/0717/products/01_ceaeea92-8fed-4d1e-83ac-4b513ef5413d_1512x.jpg?v=1671798553" alt="img" />
                                        <div className="best-p1-txt">
                                            <div className="name-of-p">
                                                <p>Sunglasses</p>
                                            </div>
                                            <div className="rating">
                                                <i className="bx bxs-star" />
                                                <i className="bx bxs-star" />
                                                <i className="bx bxs-star" />
                                                <i className="bx bx-star" />
                                                <i className="bx bx-star" />
                                            </div>
                                            <div className="price">
                                                $20.24
                                                <div className="colors">
                                                    <i className="bx bxs-circle red" />
                                                    <i className="bx bxs-circle blue" />
                                                    <i className="bx bxs-circle white" />
                                                </div>
                                            </div>
                                            <div className="buy-now">
                                                <button>
                                                    <Link to="/Article/Sunglasses">
                                                        Buy Now
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="best-p1">
                                        <img src="https://cdn.shopify.com/s/files/1/0238/8312/0717/products/01_ceaeea92-8fed-4d1e-83ac-4b513ef5413d_1512x.jpg?v=1671798553" alt="img" />
                                        <div className="best-p1-txt">
                                            <div className="name-of-p">
                                                <p>Sunglasses</p>
                                            </div>
                                            <div className="rating">
                                                <i className="bx bxs-star" />
                                                <i className="bx bxs-star" />
                                                <i className="bx bxs-star" />
                                                <i className="bx bx-star" />
                                                <i className="bx bx-star" />
                                            </div>
                                            <div className="price">
                                                $20.24
                                                <div className="colors">
                                                    <i className="bx bxs-circle red" />
                                                    <i className="bx bxs-circle blue" />
                                                    <i className="bx bxs-circle white" />
                                                </div>
                                            </div>
                                            <div className="buy-now">
                                                <button>
                                                    <Link to="/Article/Sunglasses">
                                                        Buy Now
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="best-p1">
                                        <img src="https://cdn.shopify.com/s/files/1/0238/8312/0717/products/01_ceaeea92-8fed-4d1e-83ac-4b513ef5413d_1512x.jpg?v=1671798553" alt="img" />
                                        <div className="best-p1-txt">
                                            <div className="name-of-p">
                                                <p>Sunglasses</p>
                                            </div>
                                            <div className="rating">
                                                <i className="bx bxs-star" />
                                                <i className="bx bxs-star" />
                                                <i className="bx bxs-star" />
                                                <i className="bx bx-star" />
                                                <i className="bx bx-star" />
                                            </div>
                                            <div className="price">
                                                $20.24
                                                <div className="colors">
                                                    <i className="bx bxs-circle red" />
                                                    <i className="bx bxs-circle blue" />
                                                    <i className="bx bxs-circle white" />
                                                </div>
                                            </div>
                                            <div className="buy-now">
                                                <button>
                                                    <Link to="/Article/Sunglasses">
                                                        Buy Now
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : Article === "Progessive" ?
                                <>
                                    <h2 className="uppercase">{Article}</h2>
                                    <div className="best-seller">
                                        <div className="best-p1">
                                            <img src="https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/i/blue-block-phone-&-computer-glasses:-black-full-rim-lenskart-blu-lb-e14283-c4_lenskart-blu-lb-e14283-c4-eyeglasses_lenskart-blu-lb-e14283-c4-eyeglasses_g_700325_02_2022.jpg" alt="img" />
                                            <div className="best-p1-txt">
                                                <div className="name-of-p">
                                                    <p>Progressive Glasses</p>
                                                </div>
                                                <div className="rating">
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bx-star" />
                                                    <i className="bx bx-star" />
                                                </div>
                                                <div className="price">
                                                    $20.24
                                                    <div className="colors">
                                                        <i className="bx bxs-circle red" />
                                                        <i className="bx bxs-circle blue" />
                                                        <i className="bx bxs-circle white" />
                                                    </div>
                                                </div>
                                                <div className="buy-now">
                                                    <button>
                                                        <Link to="/Article/Sunglasses">
                                                            Buy Now
                                                        </Link>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="best-p1">
                                            <img src="https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/i/blue-block-phone-&-computer-glasses:-black-full-rim-lenskart-blu-lb-e14283-c4_lenskart-blu-lb-e14283-c4-eyeglasses_lenskart-blu-lb-e14283-c4-eyeglasses_g_700325_02_2022.jpg" alt="img" />
                                            <div className="best-p1-txt">
                                                <div className="name-of-p">
                                                    <p>Progressive Glasses</p>
                                                </div>
                                                <div className="rating">
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bx-star" />
                                                    <i className="bx bx-star" />
                                                </div>
                                                <div className="price">
                                                    $20.24
                                                    <div className="colors">
                                                        <i className="bx bxs-circle red" />
                                                        <i className="bx bxs-circle blue" />
                                                        <i className="bx bxs-circle white" />
                                                    </div>
                                                </div>
                                                <div className="buy-now">
                                                    <button>
                                                        <Link to="/Article/Sunglasses">
                                                            Buy Now
                                                        </Link>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="best-p1">
                                            <img src="https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/i/blue-block-phone-&-computer-glasses:-black-full-rim-lenskart-blu-lb-e14283-c4_lenskart-blu-lb-e14283-c4-eyeglasses_lenskart-blu-lb-e14283-c4-eyeglasses_g_700325_02_2022.jpg" alt="img" />
                                            <div className="best-p1-txt">
                                                <div className="name-of-p">
                                                    <p>Progressive Glasses</p>
                                                </div>
                                                <div className="rating">
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bx-star" />
                                                    <i className="bx bx-star" />
                                                </div>
                                                <div className="price">
                                                    $20.24
                                                    <div className="colors">
                                                        <i className="bx bxs-circle red" />
                                                        <i className="bx bxs-circle blue" />
                                                        <i className="bx bxs-circle white" />
                                                    </div>
                                                </div>
                                                <div className="buy-now">
                                                    <button>
                                                        <Link to="/Article/Sunglasses">
                                                            Buy Now
                                                        </Link>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="best-p1">
                                            <img src="https://static5.lenskart.com/media/catalog/product/pro/1/thumbnail/480x480/9df78eab33525d08d6e5fb8d27136e95//l/i/blue-block-phone-&-computer-glasses:-black-full-rim-lenskart-blu-lb-e14283-c4_lenskart-blu-lb-e14283-c4-eyeglasses_lenskart-blu-lb-e14283-c4-eyeglasses_g_700325_02_2022.jpg" alt="img" />
                                            <div className="best-p1-txt">
                                                <div className="name-of-p">
                                                    <p>Progressive Glasses</p>
                                                </div>
                                                <div className="rating">
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bxs-star" />
                                                    <i className="bx bx-star" />
                                                    <i className="bx bx-star" />
                                                </div>
                                                <div className="price">
                                                    $20.24
                                                    <div className="colors">
                                                        <i className="bx bxs-circle red" />
                                                        <i className="bx bxs-circle blue" />
                                                        <i className="bx bxs-circle white" />
                                                    </div>
                                                </div>
                                                <div className="buy-now">
                                                    <button>
                                                        <Link to="/Article/Sunglasses">
                                                            Buy Now
                                                        </Link>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                </div>
                {/* <div className="seller container">
                    <h2>New Arrivals</h2>
                    <div className="best-seller">
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0661/7423/products/air-force-white-4_d1227e61-a600-4519-8a45-4bbec3d0d2a0_1024x.jpg?v=1682318540" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Eyeglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0661/7423/products/air-force-white-4_d1227e61-a600-4519-8a45-4bbec3d0d2a0_1024x.jpg?v=1682318540" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Eyeglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0661/7423/products/air-force-white-4_d1227e61-a600-4519-8a45-4bbec3d0d2a0_1024x.jpg?v=1682318540" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Eyeglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0661/7423/products/air-force-white-4_d1227e61-a600-4519-8a45-4bbec3d0d2a0_1024x.jpg?v=1682318540" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Eyeglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="seller container">
                    <h2>Hot Sales</h2>
                    <div className="best-seller">
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/jS7pSQLf/na4.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Shoes</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $37.24
                                    <div className="colors">
                                        <i className="bx bxs-circle grey" />
                                        <i className="bx bxs-circle black" />
                                        <i className="bx bxs-circle blue" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/fbnB2yfj/na1.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England T-Shirt</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.23
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/RhVP7YQk/hs1.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England T-Shirt</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $15.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle blue" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/zD02zJq8/na2.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Bag</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $09.28
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle grey" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    )
}