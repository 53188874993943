import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar } from './Navbar'

export const Collection = () => {
    return (
        <>
            <Navbar />
            <section id="sellers">
                <div className="seller container">
                    <h2>Best Sellers</h2>
                    <div className="best-seller">
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0238/8312/0717/products/01_ceaeea92-8fed-4d1e-83ac-4b513ef5413d_1512x.jpg?v=1671798553" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Sunglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $20.24
                                    <div className="colors">
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle white" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0238/8312/0717/products/01_ceaeea92-8fed-4d1e-83ac-4b513ef5413d_1512x.jpg?v=1671798553" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Sunglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $20.24
                                    <div className="colors">
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle white" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0238/8312/0717/products/01_ceaeea92-8fed-4d1e-83ac-4b513ef5413d_1512x.jpg?v=1671798553" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Sunglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $20.24
                                    <div className="colors">
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle white" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0238/8312/0717/products/01_ceaeea92-8fed-4d1e-83ac-4b513ef5413d_1512x.jpg?v=1671798553" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Sunglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $20.24
                                    <div className="colors">
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle white" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="best-p1">
                            <img
                                src="https://i.postimg.cc/76X9ZV8m/Screenshot_from_2022-06-03_18-45-12.png"
                                alt="img"
                            />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Jacket</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $17.24
                                    <div className="colors">
                                        <i className="bx bxs-circle green" />
                                        <i className="bx bxs-circle grey" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/j2FhzSjf/bs2.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Shirt</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $27.24
                                    <div className="colors">
                                        <i className="bx bxs-circle brown" />
                                        <i className="bx bxs-circle green" />
                                        <i className="bx bxs-circle blue" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/QtjSDzPF/bs3.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Shoes</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $43.67
                                    <div className="colors">
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle grey" />
                                        <i className="bx bxs-circle blue" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="seller container">
                    <h2>New Arrivals</h2>
                    <div className="best-seller">
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0661/7423/products/air-force-white-4_d1227e61-a600-4519-8a45-4bbec3d0d2a0_1024x.jpg?v=1682318540" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Eyeglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0661/7423/products/air-force-white-4_d1227e61-a600-4519-8a45-4bbec3d0d2a0_1024x.jpg?v=1682318540" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Eyeglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0661/7423/products/air-force-white-4_d1227e61-a600-4519-8a45-4bbec3d0d2a0_1024x.jpg?v=1682318540" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Eyeglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://cdn.shopify.com/s/files/1/0661/7423/products/air-force-white-4_d1227e61-a600-4519-8a45-4bbec3d0d2a0_1024x.jpg?v=1682318540" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>Eyeglasses</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="best-p1">
                            <img src="https://i.postimg.cc/zD02zJq8/na2.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Bag</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $09.28
                                    <div className="colors">
                                        <i className="bx bxs-circle brown" />
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle green" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/Dfj5VBcz/sunglasses1.jpg" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Sunglass</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $06.24
                                    <div className="colors">
                                        <i className="bx bxs-circle grey" />
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blank" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/FszW12Kc/na4.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Shoes</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $43.67
                                    <div className="colors">
                                        <i className="bx bxs-circle grey" />
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle blue" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <div className="seller container">
                    <h2>Hot Sales</h2>
                    <div className="best-seller">
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/jS7pSQLf/na4.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Shoes</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $37.24
                                    <div className="colors">
                                        <i className="bx bxs-circle grey" />
                                        <i className="bx bxs-circle black" />
                                        <i className="bx bxs-circle blue" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/fbnB2yfj/na1.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England T-Shirt</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $10.23
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle blue" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/RhVP7YQk/hs1.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England T-Shirt</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                    <i className="bx bxs-star" />
                                </div>
                                <div className="price">
                                    $15.24
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle red" />
                                        <i className="bx bxs-circle blue" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="best-p1">
                            <img src="https://i.postimg.cc/zD02zJq8/na2.png" alt="img" />
                            <div className="best-p1-txt">
                                <div className="name-of-p">
                                    <p>PS England Bag</p>
                                </div>
                                <div className="rating">
                                    <i className="bx bxs-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                    <i className="bx bx-star" />
                                </div>
                                <div className="price">
                                    $09.28
                                    <div className="colors">
                                        <i className="bx bxs-circle blank" />
                                        <i className="bx bxs-circle grey" />
                                        <i className="bx bxs-circle brown" />
                                    </div>
                                </div>
                                <div className="buy-now">
                                    <button>
                                        <Link to="/Article/Sunglasses">
                                            Buy Now
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    )
}
