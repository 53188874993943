import React from 'react'
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext';

export const Navbar = () => {

    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const OpenProfile = () => {
        navigate("/Profile")
    }

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <input type="checkbox" name="" id="checkbox" />
                <div className="hamburger-lines">
                    <span className="line line1" />
                    <span className="line line2" />
                    <span className="line line3" />
                </div>
                <ul className="menu-items">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/Collection">Shop</Link>
                    </li>
                    {/* <li>
                            <Link to="news">Blog</Link>
                        </li> */}
                    <li>
                        <Link to="/Contact">Contact</Link>
                    </li>
                    <li>
                        {currentUser ?
                            <>
                                <img src={currentUser.photoURL} className="w-10 h-10 cursor-pointer rounded-full" alt="Profile Pic" onClick={OpenProfile} />
                            </>
                            :
                            <Link to="/Login">Sign In</Link>
                        }
                    </li>
                </ul>
                <div className="logo">
                    {/* <img src="https://i.postimg.cc/TP6JjSTt/logo.webp" alt="" /> */}
                    <h1 className="text-2xl font-extrabold">LOGO</h1>
                </div>
            </div>
        </nav>
    )
}
