import { sendPasswordResetEmail } from 'firebase/auth';
import React from 'react'
import { auth } from '../firebase';
import swal from '@sweetalert/with-react';
import { useNavigate } from 'react-router-dom';

export const ResetPassword = () => {

    const navigate = useNavigate()

    const HandleReset = (e) => {
        e.preventDefault();
        const EMailID = e.target[0].value;

        sendPasswordResetEmail(auth, EMailID)
            .then(() => {
                swal("Password Reset Link Has Been Sent To Your E-Mail")
                navigate("/")
            })
            .catch((error) => {
                swal(<span className="text-red-500 uppercase font-extrabold">Something went wrong <br /> {error.code}</span>)
            });
    }

    return (
        <>
            <body id="Login">
                <div className="section">
                    <div className="container">
                        <div className="row full-height justify-content-center">
                            <div className="col-12 text-center align-self-center py-5">
                                <div className="section pb-5 pt-5 pt-sm-2 text-center">
                                    <div className="card-3d-wrap mx-auto">
                                        <div className="card-3d-wrapper">
                                            <div className="card-front">
                                                <div className="center-wrap">
                                                    <div className="section text-center">
                                                        <h4 className="mb-4 pb-3 text-2xl">RESET PASSWORD</h4>
                                                        <h3 className=''>Enter your email address and we will send you a link to reset your password</h3>
                                                        <form onSubmit={HandleReset}>
                                                            <div className="form-group mt-4">
                                                                <input required type="email" className="form-style" placeholder="Your Email" id="logemail" />
                                                                <i className="input-icon uil uil-at"></i>
                                                            </div>
                                                            <button className="btn mt-4">SUBMIT</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </>
    )
}
