/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import './App.css';
import {
  // BrowserRouter,
  useLocation,
  Route,
  Routes
} from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { Home } from './pages/Home';
import { Collection } from './pages/Collection';
import { Contact } from './pages/Contact';
import { Login } from './pages/Login';
import { ResetPassword } from './pages/ResetPassword';
import { Profile } from './pages/Profile';
import { Articles } from './pages/Articles';
import PageNotFound from './pages/PageNotFound';
import { Article } from './pages/Article';

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useState(false)
  const [prevLoc, setPrevLoc] = useState("")
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    setPrevLoc(location.pathname)
    setProgress(true)
    if (location.pathname === prevLoc) {
      setPrevLoc('')
    }
  }, [location])

  useEffect(() => {
    setProgress(false)
  }, [prevLoc])


  TopBarProgress.config({
    barThickness: 6,
    barColors: {
      "0": "#ff6961",
    },
  });

  return (
    <>
      {progress && <TopBarProgress />}
      <Routes>{children}</Routes>
    </>
  )
}

function App() {
  return (
    <CustomSwitch>
      <Route index element={<Home />} />
      <Route exact path="/Login" element={<Login />} />
      <Route exact path="/Reset" element={<ResetPassword />} />
      <Route exact path="/Contact" element={<Contact />} />
      <Route exact path="/Collection" element={<Collection />} />
      <Route exact path="/Buy/:Article" element={<Articles />} />
      <Route exact path="/Article/:Item" element={<Article />} />
      <Route exact path="/Profile" element={<Profile />} />
      <Route exact path="*" element={<PageNotFound />} />
    </CustomSwitch>
  );
}

export default App;
