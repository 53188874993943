/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { Navbar } from './Navbar'

export const Contact = () => {

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(`Thank you ${e.target[0].value}!! For submitting the form`)
    }

    return (
        <>
            <Navbar />
            <section id="contact">
                <div className="contact container">
                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202844.8913472017!2d-122.04109805!3d37.40280355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb68ad0cfc739%3A0x7eb356b66bd4b50e!2sSilicon%20Valley%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1686572575906!5m2!1sen!2sin"
                            width={600}
                            height={450}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                    {/* <form action="https://formspree.io/f/xzbowpjq" method="POST"> */}
                    <form onSubmit={handleSubmit} method="POST">
                        <div className="form">
                            <div className="form-txt">
                                <h4>INFORMATION</h4>
                                <h1>Contact Us</h1>
                                <span>
                                    Please drop a E-Mail, We'll respond within 24 hours
                                </span>
                                <h3>USA</h3>
                                <p>
                                    Mountain View, California, United States
                                    <br />
                                    +43 982-314-0958
                                </p>
                                {/* <h3>India</h3>
                                <p>
                                    HW95+C9C, Lorem ipsum dolor sit.
                                    <br />
                                    411014
                                </p> */}
                            </div>
                            <div className="form-details">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Name"
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    required
                                />
                                <textarea
                                    name="message"
                                    id="message"
                                    cols={52}
                                    rows={7}
                                    placeholder="Message"
                                    required
                                    defaultValue={""}
                                />
                                <button>SEND MESSAGE</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}