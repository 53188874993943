/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import '../styles/Login.css';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, storage, db } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from 'react-router-dom';
import TopBarProgress from "react-topbar-progress-indicator";
import LoadingBar from 'react-top-loading-bar';
import { AuthContext } from "../context/AuthContext";
import swal from '@sweetalert/with-react';

export const Login = () => {

    const CheckUncheck = (val) => {
        document.getElementById("reg-log").checked = val
    }

    const [err, setErr] = useState(false);
    // const [errorCode, setErrorCode] = useState("");
    // const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [progresspercent, setProgressPercent] = useState();
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);
    const [text, setText] = useState("");
    const [pfpFile, setPFPFile] = useState(null);

    const HandleSignup = async (e) => {
        if (pfpFile === null) {
            setLoading(false);
            e.preventDefault();
            swal("Profile Photo Required")
        } else {
            setLoading(true);
            e.preventDefault();
            const displayName = e.target[0].value;
            const EMail = e.target[1].value;
            const Password = e.target[2].value;
            const File = pfpFile;

            try {
                const res = await createUserWithEmailAndPassword(auth, EMail, Password);

                const storageRef = ref(storage, "UsersProfilePicture/" + res.user.uid);
                const uploadTask = uploadBytesResumable(storageRef, File);
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const roundedprogress = Math.round(progress);
                        setProgressPercent(roundedprogress);
                    },
                    (error) => {
                        setErr(true);
                        setLoading(false);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                            // console.log('File available at', downloadURL);
                            await updateProfile(res.user, {
                                displayName,
                                photoURL: downloadURL,
                            });
                            await setDoc(doc(db, "Users", res.user.uid), {
                                uid: res.user.uid,
                                displayName,
                                EMail,
                                photoURL: downloadURL,
                            });
                            navigate("/")
                        }
                        );

                    });

            } catch (err) {
                setErr(true);
                // setErrorCode(err.code);
                setLoading(false);
                swal(<span className="text-red-500 uppercase font-extrabold">Something went wrong <br /> {err.code}</span>)
            }

        }
    }

    const handleImageChange = e => {
        const validExtensions = ["image/jpg", "image/png", "image/jpeg"]
        if (!validExtensions.includes(e.target.files[0].type)) {
            swal("File Format Not Supported")
            setText("");
            setLoading(false);
            setPFPFile(null);
            return false
        } else {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                swal("File Size Should Be Less Than 10 MB")
                setText("");
                setLoading(false);
                setPFPFile(null);
                return false
            } else {
                setText(e.target.files[0].name)
                setPFPFile(e.target.files[0])
            }
        }
    }

    const HandleLogin = async (e) => {
        e.preventDefault()
        const EMail = e.target[0].value;
        const Password = e.target[1].value;

        try {
            setLoading(true);
            await signInWithEmailAndPassword(auth, EMail, Password);
            navigate("/")
        } catch (err) {
            setErr(true);
            // setErrorCode(err.code);
            swal(<span className="text-red-500 uppercase font-extrabold">Something went wrong <br /> {err.code}</span>)
            setLoading(false);
        }
    }

    TopBarProgress.config({
        barThickness: 8
    });

    if (!loading) {
        if (currentUser) {
            navigate("/")
        }
    }

    return (
        <>
            <body id="Login">
                {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
                <div className="section">
                    <div className="container">
                        <div className="row full-height justify-content-center">
                            <div className="col-12 text-center align-self-center py-5">
                                <div className="section pb-5 pt-5 pt-sm-2 text-center">
                                    <h6 className="mb-0 pb-3">
                                        <span className="cursor-pointer" onClick={() => CheckUncheck(false)}>Log In</span>
                                        <span className="cursor-pointer" onClick={() => CheckUncheck(true)}>Sign Up</span>
                                    </h6>
                                    <input className="checkbox" type="checkbox" id="reg-log" name="reg-log" />
                                    <label for="reg-log"></label>
                                    <div className="card-3d-wrap mx-auto">
                                        <div className="card-3d-wrapper">
                                            <div className="card-front">
                                                <div className="center-wrap">
                                                    <div className="section text-center">
                                                        <h4 className="mb-4 pb-3">Log In</h4>
                                                        <form onSubmit={HandleLogin}>
                                                            <div className="form-group">
                                                                <input required type="email" className="form-style" placeholder="Your Email" id="logemail" />
                                                                <i className="input-icon uil uil-at"></i>
                                                            </div>
                                                            <div className="form-group mt-2">
                                                                <input required type="password" className="form-style" placeholder="Your Password" id="logpass" />
                                                                <i className="input-icon uil uil-lock-alt"></i>
                                                            </div>
                                                            {/* <button className="btn mt-4">submit</button> */}
                                                            {!loading && <button className="btn mt-4">Login</button>}
                                                            {loading && <button className="btn mt-4" disabled="true">Logging In</button>}
                                                        </form>
                                                        <p className="mb-0 mt-4 text-center"><Link to="/Reset" className="link">Forgot your password?</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-back">
                                                <div className="center-wrap">
                                                    <div className="section text-center">
                                                        <h4 className="mb-4 pb-3">Sign Up</h4>
                                                        <form onSubmit={HandleSignup}>
                                                            <div className="form-group">
                                                                <input required type="text" className="form-style" placeholder="Your Full Name" id="logname" />
                                                                <i className="input-icon uil uil-user"></i>
                                                            </div>
                                                            <div className="form-group mt-2">
                                                                <input required type="email" className="form-style" placeholder="Your Email" id="logemail" />
                                                                <i className="input-icon uil uil-at"></i>
                                                            </div>
                                                            <div className="form-group mt-2">
                                                                <input required type="password" className="form-style" placeholder="Your Password" id="logpass" />
                                                                <i className="input-icon uil uil-lock-alt"></i>
                                                            </div>
                                                            <div className="form-group mt-2">
                                                                <input required type="file" className="form-style" accept="image/png, image/jpeg" placeholder="Your Profile Picture" id="pfp" onChange={(e) => handleImageChange(e)} />
                                                                <i className="input-icon uil uil-upload"></i>
                                                            </div>
                                                            {/* <button className="btn mt-4">submit</button> */}
                                                            {!loading && <button className="btn mt-4">Sign Up</button>}
                                                            {loading && <button className="btn mt-4" disabled="true">Signing Up</button>}
                                                            {/* {!loading && err && swal(<span className="text-red-500 uppercase font-extrabold">Something went wrong <br /> {errorCode}</span>)} */}
                                                            {/* {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorCode}</span>} */}
                                                            {/* {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorMessage}</span>} */}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </>
    )
}