import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_LENS,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_LENS,
  projectId: process.env.REACT_APP_PROCESS_ID_LENS,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_LENS,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_LENS,
  appId: process.env.REACT_APP_APP_ID_LENS,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_LENS,
};

// const firebaseConfig_CHATS = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_CHATS,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN_CHATS,
//   projectId: process.env.REACT_APP_PROCESS_ID_CHATS,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET_CHATS,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_CHATS,
//   appId: process.env.REACT_APP_APP_ID_CHATS,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID_CHATS,
// };

// const firebaseConfig_USERCHATS = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_USERCHATS,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN_USERCHATS,
//   projectId: process.env.REACT_APP_PROCESS_ID_USERCHATS,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET_USERCHATS,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_USERCHATS,
//   appId: process.env.REACT_APP_APP_ID_USERCHATS,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID_USERCHATS,
// };

// const firebaseConfig_GROUPS = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_GROUPS,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN_GROUPS,
//   projectId: process.env.REACT_APP_PROCESS_ID_GROUPS,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET_GROUPS,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_GROUPS,
//   appId: process.env.REACT_APP_APP_ID_GROUPS,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID_GROUPS,
// };

// const firebaseConfig_GROUPCHATS = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_GROUPCHATS,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN_GROUPCHATS,
//   projectId: process.env.REACT_APP_PROCESS_ID_GROUPCHATS,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET_GROUPCHATS,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_GROUPCHATS,
//   appId: process.env.REACT_APP_APP_ID_GROUPCHATS,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID_GROUPCHATS,
// };

// const firebaseConfig_MESSAGEDATA = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_MESSAGEDATA,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN_MESSAGEDATA,
//   projectId: process.env.REACT_APP_PROCESS_ID_MESSAGEDATA,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET_MESSAGEDATA,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_MESSAGEDATA,
//   appId: process.env.REACT_APP_APP_ID_MESSAGEDATA,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID_MESSAGEDATA,
// };

// const firebaseConfig_TYPINGSTATUS = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_TYPINGSTATUS,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN_TYPINGSTATUS,
//   projectId: process.env.REACT_APP_PROCESS_ID_TYPINGSTATUS,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET_TYPINGSTATUS,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_TYPINGSTATUS,
//   appId: process.env.REACT_APP_APP_ID_TYPINGSTATUS,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID_TYPINGSTATUS,
// };

// const firebaseConfig_MESSAGEREACTION = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY_MESSAGEREACTION,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN_MESSAGEREACTION,
//   projectId: process.env.REACT_APP_PROCESS_ID_MESSAGEREACTION,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET_MESSAGEREACTION,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_MESSAGEREACTION,
//   appId: process.env.REACT_APP_APP_ID_MESSAGEREACTION,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID_MESSAGEREACTION,
// };

export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const db = getFirestore();

// export const Chats = initializeApp(firebaseConfig_CHATS, "chats");
// // export const auth = getAuth();
// export const Chatsstorage = getStorage(Chats);
// export const Chatsdb = getFirestore(Chats);

// export const UserChats = initializeApp(firebaseConfig_USERCHATS, "userchats");
// // export const auth = getAuth();
// export const UserChatsstorage = getStorage(UserChats);
// export const UserChatsdb = getFirestore(UserChats);

// export const Groups = initializeApp(firebaseConfig_GROUPS, "groups");
// // export const auth = getAuth();
// export const Groupsstorage = getStorage(Groups);
// export const Groupsdb = getFirestore(Groups);

// export const GroupChats = initializeApp(firebaseConfig_GROUPCHATS, "groupchats");
// // export const auth = getAuth();
// export const GroupChatsstorage = getStorage(GroupChats);
// export const GroupChatsdb = getFirestore(GroupChats);

// export const MessageData = initializeApp(firebaseConfig_MESSAGEDATA, "messagedata");
// // export const auth = getAuth();
// export const MessageDatastorage = getStorage(MessageData);
// export const MessageDatadb = getFirestore(MessageData);

// export const TypingStatus = initializeApp(firebaseConfig_TYPINGSTATUS, "typingstatus");
// // export const auth = getAuth();
// export const TypingStatusstorage = getStorage(TypingStatus);
// export const TypingStatusdb = getFirestore(TypingStatus);

// export const MessageReaction = initializeApp(firebaseConfig_MESSAGEREACTION, "messagereaction");
// // export const auth = getAuth();
// export const MessageReactionstorage = getStorage(MessageReaction);
// export const MessageReactiondb = getFirestore(MessageReaction);